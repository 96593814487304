@tailwind base;
@tailwind components;

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}

@tailwind utilities;

@font-face {
  font-family: 'lorimer_no_2_condensed_bold';
  src: url('./fonts/Lorimer_No2_Cnd_Bold-webfont.woff2') format('woff2'),
  url('./fonts/Lorimer_No2_Cnd_Bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./fonts/Inter-Regular.woff2?v=3.14') format('woff2'),
  url('./fonts/Inter-Regular.woff?v=3.14') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('./fonts/Inter-Italic.woff2?v=3.14') format('woff2'),
  url('./fonts/Inter-Italic.woff?v=3.14') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./fonts/Inter-Bold.woff2?v=3.14') format('woff2'),
  url('./fonts/Inter-Bold.woff?v=3.14') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('./fonts/Inter-BoldItalic.woff2?v=3.14') format('woff2'),
  url('./fonts/Inter-BoldItalic.woff?v=3.14') format('woff');
}

@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'Regular';
  src: url('./fonts/Inter-roman-var.woff2?v=3.14') format('woff2');
}

@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
  font-named-instance: 'Italic';
  src: url('./fonts/Inter-italic-var.woff2?v=3.14') format('woff2');
}

body {
  @apply m-0 font-main-var;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

/* don't outline when clicking with mouse, just for keyboard nav */
:focus:not(:focus-visible) {
  outline: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}

.dx-texteditor-input:focus {
  @apply ring-0;
}

.imported-html {

  p,
  li {
    @apply my-4;
  }

  > div:first-child {

    > p:first-child {
      @apply text-xl;
    }

  }

  a {
    @apply rounded-sm font-bold text-teal-300 hover:text-teal-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-teal-300 focus-visible:ring-offset-2;
  }

  ul {
    @apply ml-6 list-outside list-disc;
  }

  .table-wrapper {
    @apply overflow-auto focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-violet-500;
  }

  table {
    @apply w-full table-auto text-sm leading-loose;
  }

  tr {
    @apply px-4 py-2;
  }

  thead {
    @apply bg-slate-700 font-bold text-white;
  }

  th {
    @apply px-2 text-left;
  }

  tbody tr:nth-child(2n-1) {
    @apply bg-white;
  }

  tbody tr:nth-child(2n) {
    @apply bg-slate-100;
  }

  td {
    @apply px-2;
  }

}
